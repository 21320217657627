import "./AppUpdater.css"
import {
    Box,
    Button,
    Icon,
    IconButton,
    Paper,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import React from "react";
function AppUpdater() {
    return (
        <div>
            <Typography variant="h4" class={"updater_title"}>
                App Updater
            </Typography>
            <Typography class={"date"}>(July 2022 - August 2022)</Typography>
            <Box class={"date"} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                Git repository is private
            </Box>

            <Typography variant="h6" class={"updater_desc"}>
                A full-stack application that allows users to distribute their application updates to clients.
                <br/>
                This was my first attempt at an interpreted language.
                <br/>
                This is a simple statically-typed language that supports:
                <ul>
                    <li>Variable assignment</li>
                    <li>Variable retrieval</li>
                    <li>C-Like Arithmetic and Truthy Arithmetic</li>
                    <li>Procedures and overloading (methods)</li>
                    <li>Control Flow</li>
                    <li>For, Foreach, While, Do Loops</li>
                    <li>Basic Arrays</li>
                    <li>Comments</li>
                    <li>Imports</li>
                    <li>Type casting</li>
                </ul>
                The following are the components to App Updater:
            </Typography>


            <Box display="flex" justifyContent="center" alignItems="center" > {/* Ensures the table is centered on the page */}
                <TableContainer component={Paper} elevation={3} sx={{ maxWidth: 650}}> {/* Adds shadow and limits width */}
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ minWidth: 100, borderRight: 1, borderColor: 'divider' }} align="center">Tool</TableCell>
                                <TableCell align="center">Purpose</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{borderRight: 1, borderColor: 'divider'}} align="center">App Updater Web</TableCell>
                                <TableCell align="center">web function</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{borderRight: 1, borderColor: 'divider'}} align="center">App Updater API</TableCell>
                                <TableCell align="center">nodejs function</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{borderRight: 1, borderColor: 'divider'}} align="center">C# Desktop Library</TableCell>
                                <TableCell align="center">library function</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
}

export default AppUpdater;