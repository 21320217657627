import React from "react";
import Contact_Card from "../../components/contact_card/contact_card";
import "./contact.css";
function Contact(){
    return(
        <div>
            <h1 className={"contact_title"}>Contact Me</h1>
            <Contact_Card/>
        </div>
    )
}

export default Contact;