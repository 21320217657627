import React from 'react';
import { AppBar, Toolbar, Typography, Container } from '@mui/material';
function Footer(){
    return(<div>
            <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
                <Toolbar>
                    <Typography variant="h7">
                        Website created by Noah Freelove
                    </Typography>
                </Toolbar>
            </AppBar>
    </div>
    )
}
export default Footer;