import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from "./pages/home/home";
import Navbar from "./components/navigation/navbar";
import Footer from "./components/navigation/footer";
import Projects from "./pages/projects/projects";
import Contact from "./pages/contact/contact";

function App() {
  return (
      <Router>
          <Navbar/>
          <Routes>
              <Route path='/' element={<Home/>}/>
              <Route path='/projects' element={<Projects/>}/>
              <Route path='/contact' element={<Contact/>}/>
          </Routes>
          <div style={{padding:50}}/>
          <Footer/>
      </Router>
  );
}

export default App;
