// import material-ui components
import React from 'react';
import {AppBar, Toolbar, Typography, Button} from '@mui/material';
import {Link} from 'react-router-dom';

function Navbar() {
    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                    {/*    Link without adding underline or color to '/'*/}
                        <Link to="/" style={{textDecoration: 'none', color: 'white'}}>Noah Freelove</Link>
                    </Typography>

                    <Button color="inherit" component={Link} to="/">Home</Button>
                    <Button color="inherit" component={Link} to="/projects">Projects</Button>
                    <Button color="inherit" component={Link} to="/contact">Contact</Button>

                </Toolbar>
            </AppBar>
        </div>
    );
}

export default Navbar;