import React from 'react';
import {Typography, Button} from '@mui/material';
import {Link} from 'react-router-dom';
import './projects.css';
import "../../components/projectbar"
import ProjectBar from "../../components/projectbar";
function Projects() {
    return (
        <div>
            <Typography variant="h4" class="title">
                Projects
            </Typography>
            <Typography variant="h3" class="subtitle">
                Here are some of the projects I have worked on and am currently working on.
            </Typography>

            <ProjectBar/>


        </div>
    );
}

export default Projects;