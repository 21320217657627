import {Typography, Button, Tabs, Tab, Box} from '@mui/material';
import React from 'react';
import './projectbar.css';
import "./projects/InterpreterLang/InterpreterLang"
import InterpreterLang from "./projects/InterpreterLang/InterpreterLang";
import JE2 from "./projects/JE2/JE2";
import AppUpdater from "./projects/AppUpdater/AppUpdater";
import Engine1 from "./projects/Engine_1/Engine1";

function ProjectBar() {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Tabs value={value} centered>
                <Tab sx={{ textTransform: 'none' }} label="Interpreter Lang" onClick={() => setValue(0)} />
                <Tab sx={{ textTransform: 'none' }} label="Engine_1" onClick={() => setValue(1)} />
                <Tab sx={{ textTransform: 'none' }} label="JEngine 2" onClick={() => setValue(2)} />
                <Tab sx={{ textTransform: 'none' }} label="App Updater" onClick={() => setValue(3)} />
                <Tab sx={{ textTransform: 'none' }} label="Project5" onClick={() => setValue(4)} />
            </Tabs>
            {(value === 0) && (
                <Box class="project">
                    <InterpreterLang/>
                </Box>
            )}
            {(value === 1) && (
                <Box class="project">
                    <Engine1/>
                </Box>
            )}
            {(value === 2) && (
                <Box class="project">
                    <JE2/>
                </Box>
            )}
            {(value === 3) && (
                <Box class="project">
                    <AppUpdater/>
                </Box>
            )}

        </div>
    );
}

export default ProjectBar;