import React from "react";
import {Card, Typography, IconButton, Box, Button} from "@mui/material";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import "./contact_card.css";
function Contact_Card(){
    return(
        <div>
        {/*Create a card with a github icon, a linked in icon, and an email icon*/}
            <Card class="contact_card">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <IconButton href={"https://www.linkedin.com/in/noahfreelove/"} aria-label="linkedin">
                        <LinkedInIcon sx={{ fontSize: 40 }} />
                    </IconButton>
                    <Button
                        sx={{
                            color: 'inherit',
                            textTransform: 'none',
                            padding: 0,
                            minWidth: 0, // Minimizes button padding
                            '&:hover': {
                                backgroundColor: 'transparent' // Keeps background transparent on hover
                            },
                            fontSize: 20, // Increases font size
                            fontFamily: 'inherit' // Uses the same font as the rest of the page
                        }}
                        href={"https://www.linkedin.com/in/noahfreelove/"}
                    >
                        LinkedIn
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <IconButton href={"https://github.com/NoahFreelove"} aria-label="github">
                        <GitHubIcon sx={{ fontSize: 40 }} />
                    </IconButton>
                    <Button
                        sx={{
                            color: 'inherit',
                            textTransform: 'none',
                            padding: 0,
                            minWidth: 0, // Minimizes button padding
                            '&:hover': {
                                backgroundColor: 'transparent' // Keeps background transparent on hover
                            },
                            fontSize: 20, // Increases font size
                            fontFamily: 'inherit'
                        }}
                        href={"https://github.com/NoahFreelove"}
                    >
                        GitHub
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <IconButton href={"mailto:noahfreelove@gmail.com"} aria-label="email">
                        <EmailIcon sx={{ fontSize: 40 }} />
                    </IconButton>
                    <Button
                        sx={{
                            color: 'inherit',
                            textTransform: 'none',
                            padding: 0,
                            minWidth: 0, // Minimizes button padding
                            '&:hover': {
                                backgroundColor: 'transparent' // Keeps background transparent on hover
                            },
                            fontSize: 20, // Increases font size
                            fontFamily: 'inherit' // Uses the same font as the rest of the page
                        }}
                        href={"mailto:noahfreelove@gmail.com"}
                    >
                        noahfreelove@gmail.com
                    </Button>
                </Box>
            </Card>
        </div>
    )
}

export default Contact_Card;