import "./JE2.css"
import {Box, Button, IconButton, Typography} from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import React from "react";
import GitHubIcon from "@mui/icons-material/GitHub";
function JE2() {
    return (
        <div>
            <Typography variant="h4" class={"je_title"}>
                JEngine 2
            </Typography>
            <Typography class={"date"}>(September 2022 - August 2023)</Typography>
            <Box class={"date"} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <IconButton href={"https://github.com/NoahFreelove/JE2"} aria-label="github">
                    <GitHubIcon sx={{ fontSize: 20 }} />
                </IconButton>
                <Button
                    sx={{
                        color: 'inherit',
                        textTransform: 'none',
                        padding: 0,
                        minWidth: 0, // Minimizes button padding
                        '&:hover': {
                            backgroundColor: 'transparent' // Keeps background transparent on hover
                        },
                        fontSize: 15, // Increases font size
                        fontFamily: 'inherit'
                    }}
                    href={"https://github.com/NoahFreelove/JE2"}
                >
                    GitHub Page
                </Button>
            </Box>
            <Typography variant="h6" class={"je_desc"}>
                An OpenGL based game engine with additional tools written in Java.
                <br/>
                <br/>
                This is the second iteration of JEngine, and was created in OpenGL as opposed to the first iteration which was created in JavaFX.
                JE2 improves on everything the original JEngine supported, including:
                <ul>
                    <li>Fully Featured Object Scripting</li>
                    <li>UI Library Customization</li>
                    <li>More Efficient 2D Rendering</li>
                    <li>A Better Audio Systems</li>
                    <li>Much Physics Library</li>
                    <li>More Versatile Texturing</li>
                    <li>Custom Shaders</li>
                    <li>Post Processing Effect Support</li>
                    <li>Lighting</li>
                    <li>And even more functionality with external JE2 tools</li>
                </ul>
                <br/>
                The following is a list of the external JE2 tools available:
            </Typography>

            <Box display="flex" justifyContent="center" alignItems="center" > {/* Ensures the table is centered on the page */}
                <TableContainer component={Paper} elevation={3} sx={{ maxWidth: 650}}> {/* Adds shadow and limits width */}
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ minWidth: 100, borderRight: 1, borderColor: 'divider' }} align="center">Tool Name</TableCell>
                                <TableCell align="center">Functionality</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{borderRight: 1, borderColor: 'divider'}} align="center">JE2 Editor</TableCell>
                                <TableCell align="center">Create and Edit entire JE2 Scenes including scripts and their properties.
                                    Supports save and load functionality
                                which can be loaded directly to/from the engine.</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{borderRight: 1, borderColor: 'divider'}} align="center">JE2 Object Parser</TableCell>
                                <TableCell align="center">A library used by JE2 Editor to serialize JE2 objects and their scripts.
                                    The library will recursively extract every field public or private (if annotated) and
                                    serialize it to a format which can be saved and read back</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{borderRight: 1, borderColor: 'divider'}} align="center">JE2 Shader Debugger</TableCell>
                                <TableCell align="center">A standalone tool which runs upon JE2 to quickly test how shaders
                                    look on various shapes and lighting conditions. Also has a primitive shader analyzer to catch
                                    common shader mistakes such as a missing semi-colon or curly-brace.</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{borderRight: 1, borderColor: 'divider'}} align="center">JE2 Compiler</TableCell>
                                <TableCell align="center">A library to package JE2 projects into a single, runnable jar.
                                This library uses java command line operations to compile and package your project as a jar.
                                The library also creates a main java file to bootstrap your game and to load your scenes from the
                                serialized format used by JE2 Object Parser.</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{borderRight: 1, borderColor: 'divider'}} align="center">JE2 Networking</TableCell>
                                <TableCell align="center">A library which facilitates the transfer of data over a network.
                                This tool is specialized for JE2 as it serializes in the JE2 object format over the network.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <br/>
            <Typography class={"je_desc"}>
                Screenshot of JE2 Running a Sample Project:
            </Typography>
            <Box display="flex" justifyContent="center" alignItems="center">
                <img src="je2example.png" alt="JE2 Screenshot" className="je2_screenshot"/>
            </Box>
        </div>
    );
}

export default JE2;