import React from "react";
import {Card, Typography} from "@mui/material";
import './codecard.css'
function CodeCard(props) {
    return (
        <div>
            {/*centered title for card*/}
            <Typography variant="h5" class={"code_title"}>
                {props.title}
            </Typography>
            <Card class={"code_card"}>
                {/*monospaced code font*/}
                <Typography variant="h6" style={{fontFamily: "monospace"}}>
                    {props.codearr && props.codearr.map((line) => {
                        // if empty string, add newline
                        if (line === "") {
                            return <br/>
                        }
                        return (
                            <div>
                                {line}
                            </div>
                        );
                    })}

                </Typography>
            </Card>
        </div>
    );
}

export default CodeCard;