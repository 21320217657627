import React from "react";
import {Typography, Grid, Box, IconButton, Button} from "@mui/material";
import CodeCard from "../../codecard/CodeCard";
import "./interpreterlang.css";
import GitHubIcon from "@mui/icons-material/GitHub";
function InterpreterLang() {
    return (
        <div>
            <Typography variant="h4" class={"lang_title"}>
                InterpreterLang
            </Typography>
            <Typography class={"date"}>(January 2024 - March 2024)</Typography>
            <Box class={"date"} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <IconButton href={"https://github.com/NoahFreelove/InterpreterLang"} aria-label="github">
                    <GitHubIcon sx={{ fontSize: 20 }} />
                </IconButton>
                <Button
                    sx={{
                        color: 'inherit',
                        textTransform: 'none',
                        padding: 0,
                        minWidth: 0, // Minimizes button padding
                        '&:hover': {
                            backgroundColor: 'transparent' // Keeps background transparent on hover
                        },
                        fontSize: 15, // Increases font size
                        fontFamily: 'inherit'
                    }}
                    href={"https://github.com/NoahFreelove/InterpreterLang"}
                >
                    GitHub Page
                </Button>
            </Box>
            <Typography variant="h6" class={"lang_desc"}>
                A C++ interpreter for a custom language.
                <br/>
                This was my first attempt at an interpreted language.
                <br/>
                This is a simple statically-typed language that supports:
                <ul>
                    <li>Variable assignment</li>
                    <li>Variable retrieval</li>
                    <li>C-Like Arithmetic and Truthy Arithmetic</li>
                    <li>Procedures and overloading (methods)</li>
                    <li>Control Flow</li>
                    <li>For, Foreach, While, Do Loops</li>
                    <li>Basic Arrays</li>
                    <li>Comments</li>
                    <li>Imports</li>
                    <li>Type casting</li>
                </ul>
                The following are a few examples of how to use the language:
            </Typography>
            <Grid container spacing={2} class={"codegrid"}>
                <Grid item xs={12}>
                    <CodeCard title={"Hello World"} codearr={["print(\"Hello, World!\")", "// output", "Hello, World!"]}/>
                </Grid>
                <Grid item xs={12}>
                    <CodeCard title={"Variable Assignment"} codearr={["a = 5", "print(a)", "// output", "5"]}/>
                    <CodeCard title={"Pointer Assignment"} codearr={["a = 5; b = 6", "print(a)", "a = &b","print(a)",
                        "b = 2", "print(a)", "// output", "5", "6", "2"]}/>

                </Grid>
                <Grid item xs={12}>
                    <CodeCard title={"Control Flow"} codearr={["if 5 == 5", "\tprint(\"5 == 5\")",
                        "elseif false", "print(\"This shouldn't happen...\")",
                        "else", "print(\"This also shouldn't happen...\")", "endif"]}/>
                </Grid>
                <Grid item xs={12}>
                    <CodeCard title={"Procedures (and variable scoping)"} codearr={["proc void printNum(int num)", "{", "print(num)", "}", "endproc",
                        "printNum(5)", "print(num)", "// output", "5", "Error:",
                        "-> Could not resolve variable num", "/*",
                    "Procedures do not need to be scoped with {} but you are encouraged to do so to avoid unintended behavior",
                    "*/"]}/>
                </Grid>
                <Grid item xs={12}>
                    <CodeCard title={"Arrays"} codearr={["int[3] arr; arr[0] = 1; arr[1] = 2; arr[2] = 3", "print(arr[1])",
                    "// output", "2", "", "/*", "Semicolons are optional if you only do one operation in a line. " +
                        "But they allow for you to chain multiple operations in the same line.", "*/", "",
                        "int[2][2] arr; arr[0][0] = 1; arr[1][0] = 2; arr[0][1] = 3; arr[1][1] = 4", "print(arr[1][0])",
                    "// output", "2"]}/>
                </Grid>
                <Grid item xs={12}>
                    <CodeCard title={"Imports and Type Casting"} codearr={["import \"stdlib/math.lang\"", "print(pi)", "",
                        "/* pi is a double but sin needs a float so we cast it */",
                        "float float_pi = cast pi float", "", "sin(float_pi)", "", "// output", "3.14159", "0"]}/>
                </Grid>
                <Grid item xs={12}>
                    <CodeCard title={"Loops"} codearr={["int[3] arr; arr[0] = 1; arr[1] = 2; arr[2] = 3",  "",
                        "for (int i = 0, i < 3, i++)","print(i)","endloop", "","// output", "0", "1", "2", "",
                        "while (i > 0)","print(i)", "i--","endloop", "/* This works because we didn't scope i with {} */",
                        "","// output", "3", "2", "1", "",

                        "foreach (int elm : arr)", "print(elm)", "endloop", "",
                        "// output", "1", "2", "3", "",
                        "foreach (int elm, index : arr)", "print(elm + \":\" + index) /* String operations make other types to a string */", "endloop",
                        "","// output", "1:0", "2:1", "3:2","",
                        "/* This will run once */", "dowhile(false)", "endloop",  "",

                        "/* This will run forever (but it doesnt because of the break) */",
                        "loop", "break", "endloop"
                    ]}/>
                </Grid>
            </Grid>

        </div>
    );
}

export default InterpreterLang;